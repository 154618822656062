import { interpolate } from './util';

/**
 * The request parameters you can use for all request functions
 * @typedef {Object} RequestValues
 * @property {string} values.url_slug The slug as defined by the backend
 * @property {Object} values.url_replace
 * @property {any} values.data GET or POST data
 * @property {boolean} values.json Set to true to send the data as JSON (POST only)
 * @property {boolean} values.debug Set to true if you want to log everything
 */

/**
 * @typedef {Object} Response
 * @property {'success' | 'failure' | 'error' | 'warning'} status Indicates whether the request succeeded
 * @property {any} data The data then endpoint returned
 * @property {string} message Error message if the request failed
 * @property {any} error Error details if the request failed
 */

/**
 * @param {RequestValues} values
 * @param {string} method
 * @returns {Promise<any>}
 */
export async function request(values, method = 'GET') {
	return new Promise((resolve, reject) => {
		const { url_slug, url_replace = {}, data = {}, onSuccess, onError, onWarning, json = false, debug = false } = values;
		const options = { method, headers: { Accept: 'application/json' } };
		if (json) {
			if (method !== 'POST') {
				reject(`Cannot send JSON data for method ${method}`);
				return;
			}
			if (data instanceof FormData) {
				reject('Cannot convert FormData to JSON');
				return;
			}
		}
		let url = interpolate(bigame_urls[url_slug], url_replace);
		let formData;
		if (json) {
			options.headers['Content-Type'] = 'application/json';
			formData = JSON.stringify(data);
		} else if (data instanceof FormData) {
			formData = data;
		} else {
			formData = new FormData();
			appendData(formData, data);
		}

		function appendData(formData, data, parentKey = '') {
			for (const key in data) {
				if (data.hasOwnProperty(key)) {
					let formKey = parentKey ? `${parentKey}[${key}]` : key;

					if (typeof data[key] === 'object' && !(data[key] instanceof Blob)) {
						// Recursively append data for nested objects and arrays
						appendData(formData, data[key], formKey);
					} else {
						// Append non-object data as usual
						formData.append(formKey, data[key]);
					}
				}
			}
		}
		let logObject = {};
		if (formData instanceof FormData) {
			formData.append('_token', _token); // Append token
			for (var pair of formData.entries()) {
				logObject[pair[0]] = pair[1];
			}
		} else {
			url += `?_token=${_token}`;
			logObject = data;
		}
		if (method === 'GET' || method === 'DELETE') {
			url += '?' + new URLSearchParams(formData).toString();
		} else {
			options.body = formData;
		}
		fetch(url, options)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					if (onError) {
						onError({
							status: 'failure',
							code: response.status,
							message: response.statusText,
							error: response.statusText,
						});
					}
					throw new Error('Network error: ' + response.status + ' - ' + response.statusText);
				}
			})
			.then((json) => {
				switch (json.status) {
					case 'logged_out':
						location.href = '/login';
						break;
					case 'failure':
					case 'error':
						console.log('Request error', json, logObject);
						if (onError) {
							onError(json);
						}
						reject(json);
						break;
					case 'success':
						if (debug) {
							console.log('Request success', json, logObject);
						}
						if (onSuccess) {
							onSuccess(json);
						}
						resolve(json);
						break;
					case 'warning':
						if (debug) {
							console.log('Request warning', json, logObject);
						}
						if (onWarning) {
							onWarning(json);
						}
						// When you use this, you should really check the status
						resolve(json);
						break;
				}
			})
			.catch((error) => {
				if (onError) {
					onError({
						status: 'failure',
						code: error.status,
						message: error.statusText,
						error: error.statusText,
					});
				}
				reject(error); // Reject the promise if there was an error
			});
	});
}

/** @param {RequestValues} values */
export const get = async (values) => request(values, 'GET');
/** @param {RequestValues} values */
export const put = async (values) => request(values, 'PUT');
/** @param {RequestValues} values */
export const post = async (values) => request(values, 'POST');
/** @param {RequestValues} values */
export const destroy = async (values) => request(values, 'DELETE');

export function url(url_slug, replacements = {}) {
	return interpolate(bigame_urls[url_slug], replacements);
}
